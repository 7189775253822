<script>
import Multiselect from "vue-multiselect";

import {
  contentMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      goals: [
        //{ _id: 'all',name : this.$t('personal_analyze.goal_all')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
      ],
      pageTypes: [
        {id: "product_detail", label: this.$t("personalizations.page_type_product_detail")},
        {id: "cart", label: this.$t("personalizations.page_type_cart")},
        {id: "checkout", label: 'Checkout'},
        {id: "category", label: this.$t("personalizations.page_type_category") },
        {id: "home", label: this.$t("personalizations.page_type_home") },
        {id: "product_listing", label: this.$t("personalizations.page_type_product_listing") },
        {id: "search", label: this.$t("personalizations.page_type_search") },
        {id: "any", label: this.$t("personalizations.page_type_any") },
        {id: "none", label: this.$t("personalizations.page_type_none") }
      ],
      types: [
        {id: "web", label: this.$t("personalizations.type_web")},
        {id: "recommendations", label: this.$t("personalizations.type_reco")},
        {id: "custom_code", label: this.$t("personalizations.type_custom_code") },
        {id: "pop_up", label: this.$t("personalizations.type_pop_up") },
        {id: "notifications", label: this.$t("personalizations.type_notifications") },
        {id: "feedback", label: 'Feedback' },
        {id: "api", label: this.$t('personalizations.type_api_reco') },
        {id: "api_simple", label: 'API' }
      ],
      channelTypes : [
        { id: 'any',label : this.$t('personalizations.channel_any')},
        { id: 'web',label : 'Web'},
        { id: 'app',label : 'App'},
        { id: 'server',label : 'Server'},
      ],
      auxGoal: null,
      auxPageType: null,
      auxType: null,
      auxPersonalization: null,
      auxChannelType: null,
      personalizations:[],
      filters:[],
      isOpen: null
    };
  },
  components:{Multiselect},
  props: {
    
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    ...contentMethods,

    onFilterSelected(type){
      switch (type){
        case 'goals':
          this.auxPageType = null;
          this.auxType = null;
          this.auxChannelType = null;
          this.auxPersonalization = null;
          break;
        case 'pageType':
          this.auxGoal = null;
          this.auxType = null;
          this.auxChannelType = null;
          this.auxPersonalization = null;
          break;
        case 'type':
          this.auxGoal = null;
          this.auxPageType = null;
          this.auxChannelType = null;
          this.auxPersonalization = null;
          break;
        case 'channel':
          this.auxGoal = null;
          this.auxPageType = null;
          this.auxType = null;
          this.auxPersonalization = null;
          break;
        case 'personalization':
          this.auxGoal = null;
          this.auxPageType = null;
          this.auxType = null;
          this.auxChannelType = null;
          break;
      }
    },

    loadPersonalizations(){
			
      let loader = this.$loading.show();
      
      const params = {
				q : `where[project]=${localStorage.getItem("current_project")}&limit=100`
			}

			this.getContents(params).then((contents)=>{
        if(contents&& contents.data){
          this.personalizations= contents.data;
          this.auxPersonalization = this.filters.filter(f=> f.key== 'personalization').length > 0 ? this.personalizations.filter(g=> g._id == this.filters.filter(f=> f.key== 'personalization')[0].value)[0]  :null
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text:this.$t('personalizations.get_personalizations_error'),title:  this.$t('personalizations.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    removeAll(){
      this.auxGoal = null;
      this.auxPageType = null;
      this.auxType = null;
      this.auxPersonalization = null;
      this.auxChannelType = null;
    },
    getAllFilters(){
      const filters= [];
      if(this.auxGoal){
        filters.push({
          key: 'goals',
          value: this.auxGoal._id,
          badge: `${this.$t('filter.goal')}: ${this.auxGoal.name}`
        });
      }
      
      if(this.auxPageType){
        filters.push({
          key: 'pageType',
          value: this.auxPageType.id,
          badge: `${this.$t('filter.page_type')}: ${this.auxPageType.label}`
        });
      }

      if(this.auxType){
        filters.push({
          key: 'type',
          value: this.auxType.id,
          badge: `${this.$t('filter.type')}: ${this.auxType.label}`
        });
      }

      if(this.auxPersonalization){
        filters.push({
          key: 'content',
          value: this.auxPersonalization._id,
          badge: `${this.$t('filter.personalization')}: ${this.auxPersonalization.name}`
        });
      }
      
      if(this.auxChannelType){
        filters.push({
          key: 'channelType',
          value: this.auxChannelType.id,
          badge: `${this.$t('personalizations.channel')}: ${this.auxChannelType.label}`
        });
      }
       
      return filters;
    },
    setAllFilter(filters){
      this.auxGoal = filters.filter(f=> f.key == 'goals').length > 0 ? this.goals.filter(g=> g._id == filters.filter(f=> f.key== 'goals')[0].value)[0]  :null;
      this.auxPageType = filters.filter(f=> f.key== 'pageType').length > 0 ? this.pageTypes.filter(g=> g.id == filters.filter(f=> f.key== 'pageType')[0].value)[0]  :null
      this.auxType = filters.filter(f=> f.key== 'type').length > 0 ? this.types.filter(g=> g.id == filters.filter(f=> f.key== 'type')[0].value)[0]  :null
      this.auxChannelType = filters.filter(f=> f.key== 'channelType').length > 0 ? this.types.filter(g=> g.id == filters.filter(f=> f.key== 'channelType')[0].value)[0]  :null
      this.filters = filters;
      this.loadPersonalizations();
    }
  },
  watch:{
    
  }
};
</script>

<template>
  <div class="flex-grow-1 h-100 accordion" role="tablist">
    <div class="mb-2">
      <div class="badge bg-primary m-1" v-if="auxGoal">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.goal')}}: {{auxGoal?.name}}</span>
              <i v-on:click="auxGoal =null" class="fa fa-times interact "></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxPageType">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.page_type')}}: {{auxPageType?.label}}</span>
              <i v-on:click="auxPageType =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxType">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.type')}}: {{auxType?.label}}</span>
              <i v-on:click="auxType =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxPersonalization">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.personalization')}}: {{auxPersonalization?.name}}</span>
              <i v-on:click="auxPersonalization =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxChannelType">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('personalizations.channel')}}: {{auxChannelType?.label}}</span>
              <i v-on:click="auxChannelType=null" class="fa fa-times interact"></i>
          </div>
      </div>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-0'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.goal') }}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-0`" role="tabpanel" class="mt-2" :visible="auxGoal!=null">
            <p class="small mb-1">{{ $t('filter.goal_subtitle') }}</p>
            <multiselect 
              :options="goals"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="auxGoal"
              :allow-empty="false"
              @input="onFilterSelected('goals')">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-1'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.page_type')}}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-1`" role="tabpanel" class="mt-2" :visible="auxPageType!=null">
            <p class="small mb-1">{{ $t('filter.page_type_subtitle') }}</p>
            <multiselect 
              :options="pageTypes"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="id" 
              label="label"
              :show-labels="false"
              :multiple="false"
              v-model="auxPageType"
              :allow-empty="false"
              @input="onFilterSelected('pageType')">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-2'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.type')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-2`" role="tabpanel" class="mt-2" :visible="auxType!=null">
          <p class="small mb-1">{{$t('filter.type_subtitle')}}</p>
          <multiselect 
            :options="types"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="id" 
            label="label"
            :show-labels="false"
            :multiple="false"
            v-model="auxType"
            :allow-empty="false"
            @input="onFilterSelected('type')">
          </multiselect>
        </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-3'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.personalization')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-3`" role="tabpanel" class="mt-2" :visible="auxPersonalization!=null">
          <p class="small mb-1">{{$t('filter.personalization_subtitle')}}</p>
          <multiselect 
            :options="personalizations"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="_id" 
            label="name"
            :show-labels="false"
            :multiple="false"
            v-model="auxPersonalization"
            :allow-empty="false"
            @input="onFilterSelected('personalization')">
          </multiselect>
        </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-4'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('personalizations.channel')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-4`" role="tabpanel" class="mt-2" :visible="auxChannelType!=null">
          <p class="small mb-1">{{$t('personalizations.channel_subtitle')}}</p>
          <multiselect 
            :options="channelTypes"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="id" 
            label="label"
            :show-labels="false"
            :multiple="false"
            v-model="auxChannelType"
            :allow-empty="false"
            @input="onFilterSelected('channel')">
          </multiselect>
        </b-collapse>
    </div>
  </div>
</template>
